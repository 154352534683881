import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PortfolioItem from "../components/portfolio-item"


import "./index.css"

const IndexPage = ({data}) => {
return (
  <Layout>
    <SEO title="Brad Wade Portfolio" />

    <div className="portfolio">

      {data.allMarkdownRemark.edges.map(({ node }) => (

      <PortfolioItem 
        href={node.frontmatter.href} 
        year={node.frontmatter.year} 
        title={node.frontmatter.title}
        company={node.frontmatter.company}
        imageName={node.frontmatter.imageName}
        html={node.html}
        key={node.id}
      />

      ))}
    </div>
  </Layout>
)}


export default IndexPage

export const query = graphql`
query {
  allMarkdownRemark(sort: {fields: [frontmatter___sort], order: ASC}) {
    edges {
      node {
        id
        frontmatter {
          title
          year
          imageName
          sort
          href
          company
        }
        html
      }
    }
  }
}
`
