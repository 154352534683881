import React from "react"

const PortfolioItem = (props) => {
  return (
    <div className="portfolio-item">
      <img src={'portfolio/' + props.imageName} alt={props.title + ' website thumbnail'}/>
      <div className="portfolio-item__title-wrapper">
        <h4 className="portfolio-item__title">{props.title}</h4>
        { !props.company ?  '' : 
          <div className="portfolio-item__subtitle">{props.company}</div>
        }
        <div className="portfolio-item__date">{props.year}</div>
        { !props.href ?  '' : 
          <a href={props.href} target="_blank" rel="noopener noreferrer" className="portfolio-item__link">Launch Site <i className="fa fa-external-link"></i></a>
        }
      </div>
      <div className="portfolio-item__excerpt" dangerouslySetInnerHTML={{ __html: props.html }} />
    </div>
  )
}

export default PortfolioItem
